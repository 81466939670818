Vue.http.headers.common['api-key'] = document.querySelector('meta[name=api_key]').getAttribute('content');

new Vue({
    el: '#timesheets',

    data: {
        api_url: document.querySelector('meta[name=api_url]').getAttribute('content'),

        view: 'index',
        uploaded: false,
        file_upload_response: null,
        status: 'active',
        editable: false,

        search: {
            from: '',
            to: '',
            candidate_id: 0,
            client_id: 0
        },

        timesheetForm: {
            candidate_id: 0,
            client_id: 0,
            user_ids: [],
            week_ending: ''
        },

        newTimesheet: {
            client_id: 0,
            user_ids: [],
            week_ending: moment().endOf('week').format('YYYY-MM-DD'),
            submit: 'enabled',
        },

        newTime: {
            date: '',
            started: '',
            finished: '',
            type: 'Work',
            job_ref: '',
            description: '',
            submit: 'enabled',
        },

        editTime: {
            id: 0,
            date: '',
            started: '',
            finished: '',
            type: '',
            job_ref: '',
            description: '',
            submit: 'enabled',
        },

        duplicateTime: {
            date: '',
            started: '',
            finished: '',
            type: 'Work',
            job_ref: '',
            description: '',
            submit: 'enabled',
        },

        entryTypes: [
            { text: 'Work', value: 'Work' },
            { text: 'Lunch/Break', value: 'Lunch/Break' },
            { text: 'Downtime/Admin', value: 'Downtime/Admin' }
        ]
    },

    ready: function() {

        $.fn.datepicker.defaults.autoclose = true;
        $.fn.datepicker.defaults.daysOfWeekDisabled = [1,2,3,4,5,6];
        $.fn.datepicker.defaults.daysOfWeekHighlighted = [0];
        $.fn.datepicker.defaults.startView = 'month';
        $.fn.datepicker.defaults.weekStart = 1;
        this.route();
        var self = this;
        window.onhashchange = function(e) {
            self.route();
        };
    },

    methods: {
        route: function() {
            this.$set('loading.view', true);

            var hash = (window.location.hash.substr(1)).split('/');

            // index
            if(! hash[0]) {
                this.view = 'index';
                this.getTimesheets();
                this.getCandidateList();
                this.getClientList();
                return;
            }

            // show
            if(hash.length === 1 && ! isNaN(hash[0])) {
                this.view = 'show';
                this.getTimesheet(hash[0]);
                return;
            }
        },

        // Get timesheets
        getTimesheets: function(status) {
            this.$set('loading.timesheets', true); // show loading spinner

            if(status !== 'Active' && status !== 'Pending' && status !== 'Approved' && status !== 'Paid') {
                status = 'Active';
            }

            this.status = status;

            this.$http.get(this.api_url+'/timesheets?per_page=10&status='+this.status, function(response) {
                this.loading.view = false;
                this.loading.timesheets = false; // remove loading spinner
                this.$set('timesheets', response.timesheets);
                if(typeof response.pagination !== 'undefined') {
                    this.$set('pagination', response.pagination);
                }
            }).error(function (data, status, response) {
                console.log(response);
            });
        },

        // Get a timesheet
        getTimesheet: function(id) {
            this.entryTypes = [
                { text: 'Work', value: 'Work' },
                { text: 'Lunch/Break', value: 'Lunch/Break' },
                { text: 'Downtime/Admin', value: 'Downtime/Admin' }
            ];

            this.$http.get(this.api_url+'/timesheets/'+id, function(response) {
                this.loading.view = false;
                this.$set('timesheet', response.timesheet);
                this.$set('editable', response.editable);
                if(typeof response.pagination !== 'undefined') {
                    this.$set('pagination', response.pagination);
                }
                if(response.timesheet.contract_type == 'PAYE') {
                    this.entryTypes.push({ text: 'Holiday', value: 'Holiday' });
                }

                this.timesheetForm.week_ending = moment(response.timesheet.week_ending, 'DD-MM-YYYY').format('YYYY-MM-DD');
            }).error(function (data, status, response) {
                console.log(response);
            });
        },

        approveTimesheet: function() {
            this.$http.post(this.api_url+'/timesheets/'+this.timesheet.id+'/approve', function(response) {
                this.timesheet.status = 'Approved';
            });
        },

        // Get candidates for select boxes
        getCandidateList: function() {
            this.$http.get(this.api_url+'/candidates', function(response) {
                var candidateList = [];
                candidateList.push({ text: 'All candidates', value: 0 });
                response.candidates.forEach(function(candidate) {
                    candidateList.push({
                        text: candidate.name,
                        value: candidate.id
                    });
                });
                this.$set('candidateList', candidateList);
            }).error(function (data, status, response) {
                console.log(response);
            });
        },

        // Get client for select boxes
        getClientList: function() {
            this.$http.get(this.api_url+'/clients', function(response) {
                var clientList = [];
                clientList.push({ text: 'All clients', value: 0 });
                response.clients.forEach(function(client) {
                    clientList.push({
                        text: client.name,
                        value: client.id
                    });
                });
                this.$set('clientList', clientList);
            }).error(function (data, status, response) {
                console.log(response);
            });
        },

        getCandidateClientList: function() {
            this.$http.get(this.api_url+'/candidates/'+this.timesheetForm.candidate_id+'/clients', function(response) {
                var candidateClientList = [];
                candidateClientList.push({ text: 'Select client', value: 0 });
                response.clients.forEach(function(client) {
                    candidateClientList.push({
                        text: client.name,
                        value: client.id
                    });
                });
                this.$set('candidateClientList', candidateClientList);
            }).error(function (data, status, response) {
                console.log(response);
            });
        },

        getCandidateClientUserList: function(client) {
            this.$http.get(this.api_url+'/clients/'+this.timesheetForm.client_id+'/users', function(response) {
                var candidateClientUserList = [];
                response.users.forEach(function(user) {
                    candidateClientUserList.push({
                        text: user.name + ' (' + user.email + ')',
                        value: user.id
                    });
                });
                this.$set('candidateClientUserList', candidateClientUserList);
            }).error(function (data, status, response) {
                console.log(response);
            });
        },

        // Pagination
        page: function(dir) {
            this.$set('loading.timesheets', true); // show loading spinner

            if(dir === 'prev' || dir === 'next') {
                var url = dir === 'prev' ? this.pagination.prev_page_url : this.pagination.next_page_url;
            } else {
                var url = dir;
            }

            this.$http.get(url, function(response) {
                this.loading.view = false;
                this.loading.timesheets = false; // remove loading spinner
                this.$set('timesheets', response.timesheets);
                if(typeof response.pagination !== 'undefined') {
                    this.$set('pagination', response.pagination);
                }
            }).error(function (data, status, response) {
                console.log(response);
            });
        },

        // Search timesheets
        searchTimesheets: function(e) {
            e.preventDefault();

            this.loading.timesheets = true; // show loading spinner

            var url = this.api_url+'/timesheets?per_page=10';

            url += '&status='+this.status;
            if(this.search.from) url += '&from='+this.search.from;
            if(this.search.to) url += '&to='+this.search.to;
            if(parseInt(this.search.candidate_id)) url += '&candidate_id='+this.search.candidate_id;
            if(parseInt(this.search.client_id)) url += '&client_id='+this.search.client_id;

            this.$http.get(url, function(response) {
                this.loading.timesheets = false; // remove loading spinner
                this.timesheets = response.timesheets;
                if(typeof response.pagination !== 'undefined') {
                    this.pagination = response.pagination;
                }
            }).error(function (data, status, response) {
                console.log(response);
            });
        },

        // upload file and attached to timesheet
        uploadFile: function(e) {
            e.preventDefault();
            this.$set('loading.file_upload', true); // show loading spinner

            var formData = new FormData();
            formData.append('upload', this.$$.fileInput.files[0]);

            this.$http.post(this.api_url+'/timesheets/'+this.timesheet.id+'/upload', formData, function(response) {
                this.$set('loading.file_upload', false); // hide loading spinner
                this.$set('uploaded', true); // hide loading spinner
                this.$set('file_upload_response', response.results);
                this.getDelegates(); // reload delegates
            }).error(function (data, status, response) {
                if(status == 422) {
                    this.$set('loading.file_upload', false); // hide loading spinner
                    this.$set('errors.file_upload', data.errors);
                }
            });
        },

        submitTimesheet: function() {
            this.$http.put(this.api_url+'/timesheets/'+this.timesheet.id+'/submit', function(response) {
                this.timesheet.status = 'Pending';
            }).error(function (data, status, response) {
                console.log(response);
            });
        },

        markPaid: function() {
            this.$http.put(this.api_url+'/timesheets/'+this.timesheet.id+'/paid', function(response) {
                this.timesheet.status = 'Paid';
            }).error(function (data, status, response) {
                console.log(response);
            });
        },

        showAddTimesheet: function() {
            if(typeof this.errors !== 'undefined') {
                this.errors.timesheet = null;
            }
        },

        addTimesheet: function(e) {
            e.preventDefault();
            this.$http.post(this.api_url+'/timesheets', this.timesheetForm, function(response) {
                this.$set('timesheet', response.timesheet);
                $('#addtimesheet').modal('hide');
                this.timesheetForm = {
                    candidate_id: 0,
                    client_id: 0,
                    user_ids: [],
                    week_ending: ''
                }
                window.location = '#'+this.timesheet.id;
            }).error(function (data, status, response) {
                if(status == 422) {
                     this.$set('errors.timesheet', data.errors);
                }
            });
        },

        updateTimesheet: function(e) {
            e.preventDefault();
            this.$http.put(this.api_url+'/timesheets/'+this.timesheet.id, this.timesheetForm, function(response) {
                this.$set('timesheet', response.timesheet);
                $('#edittimesheet').modal('hide');
                this.timesheetForm = {
                    candidate_id: 0,
                    client_id: 0,
                    user_ids: [],
                    week_ending: moment(response.timesheet.week_ending, 'DD-MM-YYYY').format('YYYY-MM-DD'),
                }
                window.location = '#'+this.timesheet.id;
            }).error(function (data, status, response) {
                if(status == 422) {
                     this.$set('errors.timesheet', data.errors);
                }
            });
        },

        deleteTimesheet: function(timesheet) {
            var conf = window.confirm('Are you sure you want to permenantly delete this timesheet?');

            if(conf) {
                this.$http.delete(this.api_url+'/timesheets/'+timesheet.id, function(response) {
                    this.timesheets.$remove(timesheet);
                }).error(function (data, status, response) {
                    console.log(response);
                });
            }
        },

        archiveTimesheet: function(timesheet) {
            var conf = window.confirm('Are you sure you want to permenantly archive this timesheet?');

            if(conf) {
                this.$http.delete(this.api_url+'/timesheets/'+timesheet.id, function(response) {
                    var notice = window.confirm('Timesheet has now been archived; click okay to return to Dashboard.');
                    window.location = '/';
                }).error(function (data, status, response) {
                    console.log(response);
                });
            }
        },

        setDate: function(date) {
            if(typeof this.errors !== 'undefined') {
                this.errors.entry = null;
            }
            if(typeof this.editing !== 'undefined') {
                this.editing = null;
            }
            this.newTime = {
                type: 'Work',
                date: '',
                started: '',
                finished: '',
                job_ref: '',
                description: '',
                submit: 'enabled'
            }
            this.newTime.date = date;
        },

        addTime: function(e) {
            e.preventDefault();
            this.newTime.submit = 'disabled'; // disable the submit button while we work

            var time = {
                type: this.newTime.type,
                job_ref: this.newTime.job_ref,
                description: this.newTime.description,
                started_at: this.newTime.date+' '+this.newTime.started,
                finished_at: this.newTime.date+' '+this.newTime.finished
            };

            if(typeof this.editing !== 'undefined' && this.editing !== null) {
                this.$http.put(this.api_url+'/timesheets/'+this.timesheet.id+'/entries/'+this.editing.id, time, function(response) {
                    this.timesheet = response.timesheet;
                    this.newTime = {
                        type: 'Work',
                        date: '',
                        started: '',
                        finished: '',
                        job_ref: '',
                        description: '',
                        submit: 'enabled'
                    }
                    $('#addtime').modal('hide');
                    if(typeof this.errors !== 'undefined') {
                        this.errors.entry = null;
                    }
                }).error(function (data, status, response) {
                    this.newTime.submit = 'enabled';
                    if(status == 422) {
                        this.$set('errors.entry', data.errors);
                    }
                });

                return;
            }

            this.$http.post(this.api_url+'/timesheets/'+this.timesheet.id+'/entries', time, function(response) {
                this.timesheet = response.timesheet;
                this.newTime = {
                    type: 'Work',
                    date: '',
                    started: '',
                    finished: '',
                    job_ref: '',
                    description: '',
                    submit: 'enabled'
                }
                $('#addtime').modal('hide');
                if(typeof this.errors !== 'undefined') {
                    this.errors.entry = null;
                }
            }).error(function (data, status, response) {
                this.newTime.submit = 'enabled';
                if(status == 422) {
                     this.$set('errors.entry', data.errors);
                }
            });
        },

        deleteEntry: function(entry) {
            var conf = window.confirm('Are you sure you want to delete this entry?');

            if(conf) {
                this.$http.delete(this.api_url+'/timesheets/'+this.timesheet.id+'/entries/'+entry.id, function(response) {
                    this.timesheet = response.timesheet;
                }).error(function (data, status, response) {
                    console.log(response);
                });
            }
        },

        editEntry: function(entry, date) {
            if(typeof this.errors !== 'undefined') {
                this.errors.entry = null;
            }
            var editEntry = JSON.parse(JSON.stringify(entry));
            this.$set('editing', editEntry);
            this.newTime = editEntry;
            this.newTime.started = editEntry.started_at;
            this.newTime.finished = editEntry.finished_at;
            this.newTime.date = date;
        },

        duplicateEntry: function(entry) {
            var dup = JSON.parse(JSON.stringify(entry));
            var days = [];
            for (var day in this.timesheet.days) {
                if(this.timesheet.days[day].date.day_text == 'Mon') {
                    this.duplicateTime.date = this.timesheet.days[day].date.full;
                }
                days.push({
                    text: this.timesheet.days[day].date.day_text,
                    value: this.timesheet.days[day].date.full
                });
            };
            this.$set('dayList', days);
            this.duplicateTime.started = dup.started_at;
            this.duplicateTime.finished = dup.finished_at;
            this.duplicateTime.type = dup.type;
            this.duplicateTime.job_ref = dup.job_ref;
            this.duplicateTime.description = dup.description;
        },

        duplicateTimeEntry: function(e) {
            e.preventDefault();
            this.duplicateTime.submit = 'disabled'; // disable the submit button while we work

            var time = {
                type: this.duplicateTime.type,
                job_ref: this.duplicateTime.job_ref,
                description: this.duplicateTime.description,
                started_at: this.duplicateTime.date+' '+this.duplicateTime.started,
                finished_at: this.duplicateTime.date+' '+this.duplicateTime.finished
            };

            this.$http.post(this.api_url+'/timesheets/'+this.timesheet.id+'/entries', time, function(response) {
                this.timesheet = response.timesheet;
                this.duplicateTime = {
                    type: 'Work',
                    date: '',
                    started: '',
                    finished: '',
                    job_ref: '',
                    description: '',
                    submit: 'enabled'
                }
                $('#duplicatetime').modal('hide');
                if(typeof this.errors !== 'undefined') {
                    this.errors.duplicate = null;
                }
            }).error(function (data, status, response) {
                this.duplicateTime.submit = 'enabled';
                if(status == 422) {
                     this.$set('errors.duplicate', data.errors);
                }
            });
        },

        sendCandidateTimesheetReminders: function() {
            this.$set('candidatesnotifying', true);
            this.$http.post(this.api_url+'/timesheets/alert-candidates', function(response) {
                this.candidatesnotifying = false;
                this.$set('candidatesnotified', true);
            }).error(function (data, status, response) {
                console.log(response);
            });
        },

        sendClientTimesheetReminders: function() {
            this.$set('clientsnotifying', true);
            this.$http.post(this.api_url+'/timesheets/alert-clients', function(response) {
                this.clientsnotifying = false;
                this.$set('clientsnotified', true);
            }).error(function (data, status, response) {
                console.log(response);
            });
        }
    }

});
